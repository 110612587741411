<template>
  <div id="mian">
    <div class="desc">
      <h5 class="jsxx float_left">产品报备</h5>
      <el-button
        @click="fromShow"
        class="editBt"
        type="primary"
        :disabled="inputFlag"
        >录入</el-button
      >
    </div>
    <div class="list clear">
      <ul class="listBox">
        <li>
          <span>店铺名称</span>
          <i class="hidden1">{{ detail.storeName }}</i>
        </li>
        <li>
          <span>店铺ID</span>
          <i class="hidden1">{{ detail.storeId }}</i>
        </li>
        <li>
          <span>所属商户</span>
          <i class="hidden1">{{ detail.mchName }}</i>
        </li>
        <li>
          <span>报备商户号</span>
          <i class="hidden1">{{ detail.tradeMchNo }}</i>
        </li>
        <li>
          <span>支付类型</span>
          <i class="hidden1">
            <span v-for="(v, i) in detail.productCode" :key="i">
              <span v-if="i != 0">/</span>
              {{ productCode[v] }}
            </span>
          </i>
        </li>
      </ul>
    </div>
    <div class="btn">
      <el-button @click="back" class="back">返回</el-button>
      <el-button @click="next" class="next" type="primary">确定</el-button>
    </div>
    <el-dialog title="产品报备" :visible.sync="newShopShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">店铺名称</div>
          <div class="hidden1">{{ detail.storeName }}</div>
          <div class="overAccount_ul_title">所属商户</div>
          <div class="hidden1">{{ detail.mchName }}</div>
          <div class="overAccount_ul_title">
            <i class="payTypeText">*</i>
            支付类型
          </div>

          <div style="display: flex">
            <el-radio-group v-model="radio" style="display: flex">
              <el-radio :disabled="wxpayFlag" label="wxpay">微信支付</el-radio>
              <el-radio :disabled="alipayFlag" label="alipay"
                >支付宝支付</el-radio
              >
              <el-radio :disabled="unionpayFlag" label="unionpay"
                >银联支付</el-radio
              >
            </el-radio-group>
          </div>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title">店铺ID</div>
          <div class="hidden1">{{ detail.storeId }}</div>
          <div class="overAccount_ul_title">报备商户号</div>
          <div class="hidden1">{{ detail.tradeMchNo }}</div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="newShopShow = false">取 消</el-button>
        <el-button
          class="addBt"
          :disabled="wxpayFlag && alipayFlag && unionpayFlag ? true : false"
          @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getTradeConfig, setTradeConfig } from "@/api/user/shopList.js";
export default {
  data() {
    return {
      newShopShow: false, //新增商户信息展示
      detail: "",
      productCode: {
        wxpay: "微信支付",
        alipay: "支付宝支付",
        unionpay: "银联支付",
      },
      radio: "",
      tradeProducts: [],
      wxpayFlag: false, //控制支付类型 微信单选
      alipayFlag: false, //控制支付类型 支付宝单选
      unionpayFlag: false, //控制支付类型 银联单选
      inputFlag: true, //控制录入按钮是否能被点击
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    //获取详情
    getDetail() {
      getTradeConfig(this.$route.query.id, this.$route.query.tradeMchNo).then(
        (res) => {
          if (res.resultStatus) {
            this.detail = res.resultData;

            this.detail.productCode = this.detail.productCode
              ? this.detail.productCode.split(",")
              : "";
            this.inputFlag = false;
            // console.log("啊啊啊啊啊啊啊啊啊啊啊啊", this.detail);
          }
        }
      );
    },
    //录入展示
    fromShow() {
      if (this.detail.productCode != "" && this.detail.productCode.length > 0) {
        this.detail.productCode.forEach((e) => {
          if (e == "alipay") {
            this.alipayFlag = true;
          } else if (e == "wxpay") {
            this.wxpayFlag = true;
          } else {
            this.unionpayFlag = true;
          }
        });
      }
      this.radio = "";
      this.newShopShow = true;
    },
    // 选中支付类型时
    // statusChange(value) {
    //   console.log("选中支付类型时", value);
    // },
    //提交
    submit() {
      let data = {
        productCode: this.radio,
      };
      if (!data.productCode) {
        this.$message.error("支付类型必选");
        return;
      }

      // console.log("录入确定时", data);

      // return;
      setTradeConfig(
        this.$route.query.id,
        this.$route.query.tradeMchNo,
        data
      ).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail();
          this.newShopShow = false;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    next() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";

.overAccount_ul {
  overflow: hidden;
}
.overAccount_ul .hidden1 {
  height: 19px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}
.payTypeText {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

</style>
